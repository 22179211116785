import { ISettingData, Action } from "./SettingInterface";
import { SettingsTypes } from "./SettingsTypes";
import { themes } from '../../themes/initThemes'
import layout1Settings from '../../components/Layouts/Layout1/Layout1Settings'

const initialState: ISettingData = {
  loading: false,
  isUpdateLoading: false,
  settingsData: {},
  admin: {
    activeLayout: 'layout1', // layout1, layout2
    activeTheme: 'green', // View all valid theme colors inside MatxTheme/themeColors.js
    perfectScrollbar: false,

    themes: themes,
    layout1Settings, // open Layout1/Layout1Settings.js

    secondarySidebar: {
        show: true,
        open: false,
        theme: 'slateDark1', // View all valid theme colors inside MatxTheme/themeColors.js
    },
    // Footer options
    footer: {
        show: true,
        fixed: false,
        theme: 'slateDark1', // View all valid theme colors inside MatxTheme/themeColors.js
    },
},
};

export const settingsReducer = (
  state: ISettingData = initialState,
  action: Action
) => {
  switch (action.type) {
    case SettingsTypes.HYDRATE: {
      return {
        ...state, // use previous state
        ...action.payload.siteSettings
      } 
    }
    case SettingsTypes.SETTINGS_LOADING: {
      return {
        ...state,
        loading: action.payload,
      };
    }

    case SettingsTypes.ADMIN_SETTINGS: {
      return {
        ...state,
        admin: {
          ...state.admin,
          ...action.payload
        },
      };
    }
    case SettingsTypes.SETTINGS: {
      return {
        ...state,
        loading: false,
        settingsData: action.payload,
      };
    }

    default:
      return state;
  }
};
