import { legacy_createStore as createStore, applyMiddleware } from 'redux';
import rootReducer from './RootReducer';
import logger from 'redux-logger';
import thunk from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';
import storage from 'redux-persist/lib/storage'
import {  persistStore, persistReducer } from 'redux-persist'
const persistConfig = {
    key: "root",
    storage: storage,
    whiteList: [],
  };

let middleware: any[] = [];
if (process.env.NODE_ENV === 'development') {
    middleware = [...middleware, logger, thunk];
} else {
    middleware = [...middleware, thunk];
} 
const persisted = persistReducer(persistConfig, rootReducer);

export const store = createStore(persisted, composeWithDevTools(applyMiddleware(...middleware)));
export const persistor:any = persistStore(store);