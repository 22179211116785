import { Dispatch } from 'redux';

import { AlertAction, IAlertPayload } from '../alerts/AlertInterface';
import { RecruitmentAgenciesType } from './RecruitmentAgenciesTypes';
import { client } from '../../utils';
import { Action, IFormState } from './RecruitmentAgenciesInterface';
import { AlertType } from '../alerts/AlertTypes';
import { IResponseData } from '../common/CommonInterface';

export const getRecruitmentAgencies = () => {
  return async (dispatch: Dispatch<Action | AlertAction>) => {
    try {
      dispatch({ type: RecruitmentAgenciesType.RA_LIST_REQUEST, payload: true });
      const response: IResponseData = await client(`/recruitment-agencies/kanban`, { body: {} });
      dispatch({
        type: RecruitmentAgenciesType.RA_LIST_SUCCESS,
        payload: response.data,
      });
      dispatch({ type: RecruitmentAgenciesType.RA_LIST_REQUEST, payload: false });

      return response;
    } catch (error: any) {
      dispatch({ type: RecruitmentAgenciesType.RA_LIST_REQUEST, payload: false });

      const errorPayload: IAlertPayload = { message: error.message };
      dispatch({
        type: AlertType.ALERT_ERROR,
        payload: errorPayload,
      });
      return error;
    }
  };
};

export const createOrUpdateRecruitmentAgencies = (data: IFormState) => {
  return async (dispatch: Dispatch<Action | AlertAction>) => {
    try {
      dispatch({ type: RecruitmentAgenciesType.RA_FORM_REQUEST, payload: true });
      const response: IResponseData = await client(`/recruitment-agencies/${data.action}`, { body: data.body });

      let alertPayload: IAlertPayload = { message: response?.message || '' };
      if (response && response.success) {
        dispatch({ type: RecruitmentAgenciesType.RA_FORM_MODAL_CLOSE });
        dispatch({
          type: AlertType.ALERT_SUCCESS,
          payload: alertPayload,
        });
      } else {
        dispatch({
          type: AlertType.ALERT_ERROR,
          payload: alertPayload,
        });
      }

      dispatch({ type: RecruitmentAgenciesType.RA_FORM_REQUEST, payload: false });

      return response;
    } catch (error: any) {
      dispatch({ type: RecruitmentAgenciesType.RA_FORM_REQUEST, payload: false });

      const errorPayload: IAlertPayload = { message: error.message };
      dispatch({
        type: AlertType.ALERT_ERROR,
        payload: errorPayload,
      });
      return error;
    }
  };
};
export const deleteRecruitmentAgencies = (body: any) => {
  return async (dispatch: Dispatch<Action | AlertAction>) => {
    try {
      dispatch({ type: RecruitmentAgenciesType.RA_DELETE_REQUEST, payload: true });
      const response: IResponseData = await client(`/recruitment-agencies/delete`, { body });
      if (response && response.success) {
        dispatch({ type: RecruitmentAgenciesType.RA_DELETED, payload: body.ids });
      }
      let alertPayload: IAlertPayload = { message: response?.message || '' };

      dispatch({
        type: response && response.success ? AlertType.ALERT_SUCCESS : AlertType.ALERT_ERROR,
        payload: alertPayload,
      });

      dispatch({ type: RecruitmentAgenciesType.RA_DELETE_REQUEST, payload: false });

      return response;
    } catch (error: any) {
      dispatch({ type: RecruitmentAgenciesType.RA_DELETE_REQUEST, payload: false });

      const errorPayload: IAlertPayload = { message: error.message };
      dispatch({
        type: AlertType.ALERT_ERROR,
        payload: errorPayload,
      });
      return error;
    }
  };
};