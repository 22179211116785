import Dashboard from './Dashboard/routes';
import Recruitment from './Recruitment/routes';
import Users from './Users/routes';

import Auth from './Auth/routes';

export let AuthRoute = Auth.routesProps 
let Modules =[
    Dashboard,
    Recruitment,
    Users,
]
 
let ModulesList: any = []
for(var i in Modules){
    ModulesList = [...ModulesList, ...Modules[i].routesProps]  
}

let newArraty: any = []
// for (let index = 0; index < ModulesList.length; index++) {
    for (let i = 0; i < ModulesList.length; i++) {
            newArraty.push(ModulesList[i])
    }
// } 

newArraty.sort((a:any,b:any) => a.sort - b.sort)
export default newArraty;