export enum ProfileType {
  SET_INITIAL_LOADING = "SET_INITIAL_LOADING",

  USER_PROFILE_DATA = "USER_PROFILE_DATA",
  USER_PROFILE_REQUEST = "USER_PROFILE_REQUEST",
  USER_PROFILE_SUCCESS = "USER_PROFILE_SUCCESS",
  USER_PROFILE_ERROR = "USER_PROFILE_ERROR",

  USER_PROFILE_ADDRESS_BOOK_REQUEST = "USER_PROFILE_ADDRESS_BOOK_REQUEST",
  USER_PROFILE_ADDRESS_BOOK_SUCCESS = "USER_PROFILE_ADDRESS_BOOK_SUCCESS",
  USER_PROFILE_ADDRESS_BOOK_ERROR = "USER_PROFILE_ADDRESS_BOOK_ERROR",

  PRIMARY_ADDRESS_CHANGING_REQUEST = "PRIMARY_ADDRESS_CHANGING_REQUEST"
}
