import React, { memo } from 'react';
import { Box } from '@mui/material';
import { FCState } from '../../stores/RootReducer';
import { useSelector } from 'react-redux';

interface IPermissionCheck {
    children?: React.ReactNode,
    permission: string
}
const PermissionCheck = ({ children,permission }: IPermissionCheck) => {
    let user: any = useSelector((state: FCState) => state.login.userData)
    let per = permission?.split('.')
    console.log(user.role.permissions,permission,'---user.role.permissions');
    
    if(user.role.permissions[per?.[0]]?.[per?.[1]]){
        return (<Box>
            {children}
         </Box>) 
    }else{
        return (<></>)
    }
    
}
export default memo(PermissionCheck)