import { lazy } from "react";
const Dashboard = lazy(() => import("../Dashboard/view/index"));

export default {
    routesProps:
        [
            {
                name: 'Dashboard',
                path: '/',
                icon: 'dashboard',
                sort: 0,
                component: Dashboard,
                permission: 'dashboard.index',
            },

        ]
};