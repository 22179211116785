import { lazy } from "react";
const Users = lazy(() => import("./view/index"));
const Roles = lazy(() => import("./view/roles/index"));
const CreateRole = lazy(() => import("./view/roles/create"));
const EditRole = lazy(() => import("./view/roles/edit"));
const CreateUser = lazy(() => import("./view/create"));
const EditUser = lazy(() => import("./view/edit"));

export default {
    routesProps:
        [
            {
                name: 'Team Management',
                path: '/team-management',
                icon: 'people',
                sort: 5,
                component: Users,
                permission: 'team.index',
                children: [
                    {
                        name: 'Team',
                        path: '/team',
                        permission: 'team.index',
                        component: Users,
                    },
                    {
                        name: 'Roles',
                        path: '/roles',
                        permission: 'roles.index',
                        component: Roles,
                    },
                ]
            },
            {
                path: '/team/create',
                permission: 'team.create',
                component: CreateUser,
            },
            {
                path: '/team/edit/:id',
                permission: 'team.update',
                component: EditUser,
            },
            {
                path: '/roles/create',
                permission: 'roles.create',
                component: CreateRole,
            },
            {
                path: '/roles/edit/:id',
                permission: 'roles.update',
                component: EditRole,
            },

        ]
};