import { combineReducers } from "redux";
import { settingsReducer } from "./settings/SettingsReducer";
import { alertReducer } from "./alerts/AlertsReducer";
import { userLoginReducer } from "./login/LoginReducer"; 
import { UserLoginType } from "./login/LoginTypes";
import { profileReducer } from "./profile/ProfileReducer";
import { quotaReducer } from "./quota/QuotaReducer";
import { recruitmentAgenciesReducer } from "./recruitmentAgencies/RecruitmentAgenciesReducer";
 

const combineReducer = combineReducers({
  alert: alertReducer, 
  login: userLoginReducer,
  settings: settingsReducer,
  profile: profileReducer,
  quota: quotaReducer,
  recruitmentAgencies: recruitmentAgenciesReducer,
});

const rootReducer = (state: any, action: any) => {
  //action to clear application redux
  if (action.type === UserLoginType.USER_LOGOUT) {
    state = {};
  }
  return combineReducer(state, action);
}
export type FCState = ReturnType<typeof rootReducer>;
export default rootReducer;
