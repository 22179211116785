import { lazy } from "react";
const CandidateList = lazy(() => import("./view/CandidateList/index"));
const CandidateView = lazy(() => import("./view/CandidateList/view"));
const CandidateListEdit = lazy(() => import("./view/CandidateList/edit"));
const RecruitmentAgencies = lazy(() => import("./view/RecruitmentAgencies/index"));
const RecruitmentAgenciesView = lazy(() => import("./view/RecruitmentAgencies/view"));
const CandidatesInterview = lazy(() => import("./view/CandidatesInterview/index"));
const CandidatesInterviewView = lazy(() => import("./view/CandidatesInterview/view"));
const CandidatesInterviewViewSingleProfile = lazy(() => import("./view/CandidatesInterview/InterviewOrNotes"));

export default {
  routesProps:
    [
      {
        name: 'Recruitment',
        path: '/reviews',
        icon: 'person_outline_outlined',
        permission: 'reviews.index',
        sort: 2,
        children: [
          {
            name: "Candidate List",
            path: "/quota",
            permission: "reviews.index",
            component: CandidateList,
          },
          {
            name: "Recruitment Agencies",
            path: "/recruitment-agencies",
            permission: "reviews.index",
            component: RecruitmentAgencies,
          },
          {
            name: "Candidates Interview",
            path: "/candidates-interview",
            permission: "reviews.index",
            component: CandidatesInterview,
          },
          {
            name: "Candidates Joining",
            path: "/candidates-interview",
            permission: "reviews.index",
            component: CandidatesInterview,
          },
          {
            name: "Candidate Legal Contract",
            path: "/candidates-interview",
            permission: "reviews.index",
            component: CandidatesInterview,
          },
        ],
      },
      {
        path: '/quota/view/:id',
        permission: 'reviews.view',
        component: CandidateView
      },{
        path: '/quota/edit/:id',
        permission: 'reviews.view',
        component: CandidateListEdit
      }, {
        path: '/recruitment-agencies/view/:id',
        permission: 'reviews.view',
        component: RecruitmentAgenciesView
      },
      {
        path: '/candidates-interview/view/:id',
        permission: 'reviews.view',
        component: CandidatesInterviewView
      }, 
      {
        path: '/candidates-interview/view/:id/:id',
        permission: 'reviews.view',
        component: CandidatesInterviewViewSingleProfile
      }
    ]
};