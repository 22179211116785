export enum UserLoginType {
    USER_LOGIN_REQUEST = 'USER_LOGIN_REQUEST',
    USER_LOGIN_SUCCESS = 'USER_LOGIN_SUCCESS',
    USER_PROFILE_DATA = 'USER_PROFILE_DATA',
    USER_LOGIN_ERROR = 'USER_LOGIN_ERROR',
    USER_LOGOUT = 'USER_LOGOUT',

    FORGOT_PASSWORD_REQUEST = 'FORGOT_PASSWORD_REQUEST',
    FORGOT_PASSWORD_SUCCESS = 'FORGOT_PASSWORD_SUCCESS',
    FORGOT_PASSWORD_ERROR = 'FORGOT_PASSWORD_ERROR',

    UPDATE_PASSWORD_REQUEST = 'UPDATE_PASSWORD_REQUEST',
    UPDATE_PASSWORD_SUCCESS = 'UPDATE_PASSWORD_SUCCESS',
    UPDATE_PASSWORD_ERROR = 'UPDATE_PASSWORD_ERROR'
}