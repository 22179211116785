import { IUserLoginState, UserLoginActions } from './LoginInterface';
import { UserLoginType } from './LoginTypes';

const initialState: IUserLoginState = {
    loading: false,
    isLoggedIn: false,
    errorMessage: null,
    userData: null
}

export const userLoginReducer = (state: IUserLoginState = initialState, action: UserLoginActions) => {
    switch (action.type) {
        case UserLoginType.USER_LOGIN_REQUEST:
            return {
                ...state, loading: true, isLoggedIn: false
            }
        case UserLoginType.USER_LOGIN_SUCCESS:
            return {
                ...state, loading: false, isLoggedIn: true
            }
        case UserLoginType.USER_PROFILE_DATA:
            return {
                ...state, userData: action.payload
            }
            
        case UserLoginType.USER_LOGIN_ERROR:
            return {
                ...state, loading: false, isLoggedIn: false
            }
        case UserLoginType.USER_LOGOUT:
            return {
                ...state, isLoggedIn: false
            }
        case UserLoginType.FORGOT_PASSWORD_REQUEST:
        case UserLoginType.UPDATE_PASSWORD_REQUEST:
            return {
                ...state, loading: true
            }
        case UserLoginType.FORGOT_PASSWORD_SUCCESS:
        case UserLoginType.UPDATE_PASSWORD_SUCCESS:
            return {
                ...state, loading: false
            }
        case UserLoginType.FORGOT_PASSWORD_ERROR:
        case UserLoginType.UPDATE_PASSWORD_ERROR:
            return {
                ...state, loading: false
            }
        default:
            return state;
    }
}
