import { Action, IQuotaState } from "./QuotaInterface";
import { QuotaType } from "./QuotaTypes";
import { v4 as uuidv4 } from "uuid";

const initialState: IQuotaState = {
  loading: false,
  formLoading: false,
  quotaList: [],
  closeModalTrigger: 0,
  isDeleteRequestLoading: false
};

export const quotaReducer = (
  state: IQuotaState = initialState,
  action: Action
) => {
  switch (action.type) {
    case QuotaType.QUOTA_LIST_SUCCESS: {
      let kanbanData:any = [{
        id: uuidv4(),
        title: 'Requites Candidate Quota',
        isDragDisabled: true,
        items: []
      },{
        id: uuidv4(),
        title: 'Approved by GM',
        isDragDisabled: true,
        items: []
      },{
        id: uuidv4(),
        title: 'Apply for Visa Quota Approval',
        isDragDisabled: true,
        items: []
      },{
        id: uuidv4(),
        title: 'Approved  Visa Quota',
        isDragDisabled: true,
        items: []
      }]

      for(let item of action.payload){
        if (!item?.isSendToGM) {
          kanbanData[0].items.push(item)
        }
        else if (!item?.isSendToGM && item?.isGMApproval && item?.applicationNo?.length ==0) {
          kanbanData[1].items.push(item)
        }
        else if (item?.applicationNo && item?.visaApplicationNo?.length ==0) {
          kanbanData[2].items.push(item)
        }
        else if (item?.applicationNo && item?.visaApplicationNo) {
          kanbanData[3].items.push(item)
        }
      }
      return {
        ...state,
        quotaList: kanbanData
      };
    }
    case QuotaType.QUOTA_FORM_REQUEST: {
      return {
        ...state,
        formLoading: action.payload,
      };
    }
    case QuotaType.QUOTA_DELETE_REQUEST: {
      return {
        ...state,
        isDeleteRequestLoading: action.payload,
      };
    }
    case QuotaType.QUOTA_DELETED: {
      let newQuotaList = state.quotaList
      for(let i in newQuotaList){
        let findedIndex = newQuotaList[i].items.findIndex((val:any) =>action.payload.includes(val.id))
        if(findedIndex!= -1){
          newQuotaList[i].items.splice(findedIndex, 1);
        }
      }
      return {
        ...state,
        quotaList: [...newQuotaList],
      };
    }
    case QuotaType.QUOTA_FORM_MODAL_CLOSE: {
      return {
        ...state,
        closeModalTrigger: state.closeModalTrigger + 1,
      };
    }
    case QuotaType.QUOTA_LIST_REQUEST: {
      return {
        ...state,
        loading: action.payload,
      };
    }
    default:
      return state;
  }
};
