import { Dispatch } from 'redux';

import { AlertAction, IAlertPayload } from '../alerts/AlertInterface';
import { QuotaType } from './QuotaTypes';
import { client } from '../../utils';
import { Action, IFormState } from './QuotaInterface';
import { AlertType } from '../alerts/AlertTypes';
import { IResponseData } from '../common/CommonInterface';

export const getQuotaList = () => {
  return async (dispatch: Dispatch<Action | AlertAction>) => {
    try {
      dispatch({ type: QuotaType.QUOTA_LIST_REQUEST, payload: true });
      const response: IResponseData = await client(`/quota/kanban`, { body: {} });
      dispatch({
        type: QuotaType.QUOTA_LIST_SUCCESS,
        payload: response.data,
      });
      dispatch({ type: QuotaType.QUOTA_LIST_REQUEST, payload: false });

      return response;
    } catch (error: any) {
      dispatch({ type: QuotaType.QUOTA_LIST_REQUEST, payload: false });

      const errorPayload: IAlertPayload = { message: error.message };
      dispatch({
        type: AlertType.ALERT_ERROR,
        payload: errorPayload,
      });
      return error;
    }
  };
};

export const createOrUpdateQuota = (data: IFormState) => {
  return async (dispatch: Dispatch<Action | AlertAction>) => {
    try {
      dispatch({ type: QuotaType.QUOTA_FORM_REQUEST, payload: true });
      const response: IResponseData = await client(`/quota/${data.action}`, { body: data.body });

      let alertPayload: IAlertPayload = { message: response?.message || '' };
      if (response && response.success) {
        dispatch({ type: QuotaType.QUOTA_FORM_MODAL_CLOSE });
        dispatch({
          type: AlertType.ALERT_SUCCESS,
          payload: alertPayload,
        });
      } else {
        dispatch({
          type: AlertType.ALERT_ERROR,
          payload: alertPayload,
        });
      }

      dispatch({ type: QuotaType.QUOTA_FORM_REQUEST, payload: false });

      return response;
    } catch (error: any) {
      dispatch({ type: QuotaType.QUOTA_FORM_REQUEST, payload: false });

      const errorPayload: IAlertPayload = { message: error.message };
      dispatch({
        type: AlertType.ALERT_ERROR,
        payload: errorPayload,
      });
      return error;
    }
  };
};
export const deleteQuota = (body: any) => {
  return async (dispatch: Dispatch<Action | AlertAction>) => {
    try {
      dispatch({ type: QuotaType.QUOTA_DELETE_REQUEST, payload: true });
      const response: IResponseData = await client(`/quota/delete`, { body });
      if (response && response.success) {
        dispatch({ type: QuotaType.QUOTA_DELETED, payload: body.ids });
      }
      let alertPayload: IAlertPayload = { message: response?.message || '' };

      dispatch({
        type: response && response.success ? AlertType.ALERT_SUCCESS : AlertType.ALERT_ERROR,
        payload: alertPayload,
      });

      dispatch({ type: QuotaType.QUOTA_DELETE_REQUEST, payload: false });

      return response;
    } catch (error: any) {
      dispatch({ type: QuotaType.QUOTA_DELETE_REQUEST, payload: false });

      const errorPayload: IAlertPayload = { message: error.message };
      dispatch({
        type: AlertType.ALERT_ERROR,
        payload: errorPayload,
      });
      return error;
    }
  };
};