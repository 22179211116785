import { Dispatch } from "redux";
import { AlertAction, IAlertPayload } from "../alerts/AlertInterface";
import { AlertType } from "../alerts/AlertTypes";
import {client} from "../../utils";
import { IResponseData } from "../common/CommonInterface";
import { UserLoginType } from "./LoginTypes";
import {
  IUserLoginRequestData,
  UserLoginActions,
  IForgotPasswordRequestData,
  IUpdatePasswordRequestData,
} from "./LoginInterface";

// const baseUrl = process.env.NODE_ENV ? `${process.env.REACT_APP_AUTH_API_URL}auth/v1/` : `${process.env.REACT_APP_BASE_API_URL}auth/v1/`;
 

export const userLogin = (body: IUserLoginRequestData) => {
  return async (dispatch: Dispatch<UserLoginActions | AlertAction>) => {
    try {
      dispatch({ type: UserLoginType.USER_LOGIN_REQUEST });
      const response: IResponseData = await client('/auth/tenant/login',  { body })

      if(response && response.success){
        dispatch({ type: UserLoginType.USER_LOGIN_SUCCESS });
        dispatch({ type: UserLoginType.USER_PROFILE_DATA, payload: response.data})

      }else{
        dispatch({ type: UserLoginType.USER_LOGIN_ERROR});
          const errorPayload: IAlertPayload = {
            message: response?.message || '',
            delay: 10000,
          };
          dispatch({
            type: AlertType.ALERT_ERROR,
            payload: errorPayload,
          });
      }
      

      return response;
    } catch (error: any) {
      dispatch({ type: UserLoginType.USER_LOGIN_ERROR});
      const errorPayload: IAlertPayload = {
        message: error?.message || '',
        delay: 10000,
      };
      dispatch({
        type: AlertType.ALERT_ERROR,
        payload: errorPayload,
      });
      return error;
    }
  };
};
export const userSignup = (body: IUserLoginRequestData) => {
  return async (dispatch: Dispatch<any | AlertAction>) => {
    try {
      dispatch({ type: UserLoginType.USER_LOGIN_REQUEST });
      const response: IResponseData = await client('/auth/signup',  { body })

      if(response && response.success){
        dispatch({ type: UserLoginType.USER_LOGIN_SUCCESS });
        dispatch({ type: UserLoginType.USER_PROFILE_DATA, payload: response.data})

      }else{
        dispatch({ type: UserLoginType.USER_LOGIN_ERROR});
          const errorPayload: IAlertPayload = {
            message: response?.message || '',
            delay: 10000,
          };
          dispatch({
            type: AlertType.ALERT_ERROR,
            payload: errorPayload,
          });
      }
      

      return response;
    } catch (error: any) {
      dispatch({ type: UserLoginType.USER_LOGIN_ERROR});
      const errorPayload: IAlertPayload = {
        message: error?.message || '',
        delay: 10000,
      };
      dispatch({
        type: AlertType.ALERT_ERROR,
        payload: errorPayload,
      });
      return error;
    }
  };
};

export const userLogout = () => {
  return async (dispatch: Dispatch<UserLoginActions>) => {
    dispatch({ type: UserLoginType.USER_LOGOUT });
  };
};

export const forgotPassword = (data: IForgotPasswordRequestData) => {
  return async (dispatch: Dispatch<UserLoginActions | AlertAction>) => {
    try {
      dispatch({ type: UserLoginType.FORGOT_PASSWORD_REQUEST });
      const response: IResponseData = await client(
        `forgot-password`,
        
      );
      dispatch({ type: UserLoginType.FORGOT_PASSWORD_SUCCESS });
      return response;
    } catch (error: any) {
      dispatch({ type: UserLoginType.FORGOT_PASSWORD_ERROR });
      const errorPayload: IAlertPayload = {
        message: error.message,
        delay: 10000,
      };
      dispatch({
        type: AlertType.ALERT_ERROR,
        payload: errorPayload,
      });
      return error;
    }
  };
};

export const updatePassword = (data: IUpdatePasswordRequestData) => {
  return async (dispatch: Dispatch<UserLoginActions | AlertAction>) => {
    try {
      dispatch({ type: UserLoginType.UPDATE_PASSWORD_REQUEST });
      const response: IResponseData = await client(
        `update-password`,
        
      );
      dispatch({ type: UserLoginType.UPDATE_PASSWORD_SUCCESS });
      dispatch({ type: AlertType.ALERT_SUCCESS, payload: { message: response.message } });
      return response;
    } catch (error: any) {
      dispatch({ type: UserLoginType.UPDATE_PASSWORD_ERROR });
      const errorPayload: IAlertPayload = {
        message: error.message,
        delay: 10000,
      };
      dispatch({
        type: AlertType.ALERT_ERROR,
        payload: errorPayload,
      });
      return error;
    }
  };
};
