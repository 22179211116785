import { Action, IProfileState } from "./ProfileInterface";
import { ProfileType } from "./ProfileTypes";

const initialState: IProfileState = {
  loading: false,
  isProfileLoading: true,
  isAddressBookLoading: false,
  isPrimaryAddressLoading: false,
  profileData: {},
  addressBook: [],
  
};

export const profileReducer = (
  state: IProfileState = initialState,
  action: Action
) => {
  switch (action.type) {
    case ProfileType.USER_PROFILE_DATA: {
      return {
        ...state,
        profileData: action.payload,
      };
    }
    case ProfileType.PRIMARY_ADDRESS_CHANGING_REQUEST: {
      return {
        ...state,
        isPrimaryAddressLoading: action.payload,
      };
    }
    case ProfileType.USER_PROFILE_ADDRESS_BOOK_SUCCESS: {
      return {
        ...state,
        isAddressBookLoading: false,
        addressBook: action.payload,
      };
    }

    case ProfileType.USER_PROFILE_ADDRESS_BOOK_REQUEST: {
      return {
        ...state,
        isAddressBookLoading: action.payload,
      };
    }
    case ProfileType.USER_PROFILE_ADDRESS_BOOK_SUCCESS: {
      return {
        ...state,
        isAddressBookLoading: false,
        addressBook: action.payload,
      };
    }
    default:
      return state;
  }
};
