import React, { useEffect, useCallback } from "react";
import { useSelector } from 'react-redux';
import { clearAlert } from '../../stores';
import { useAppDispatch } from '../../stores/appDispatch';
import { FCState } from '../../stores/RootReducer';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert, { AlertProps } from '@mui/material/Alert';

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
    props,
    ref,
) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function Toast() {
    const { delay, message, open, status } = useSelector((state: FCState) => state.alert);
    const dispatch = useAppDispatch();

    const handleClose = useCallback(() => {
        dispatch(clearAlert());
    }, [dispatch]);

    useEffect(() => {
        if (open) {
            setTimeout(() => {
                handleClose()
            }, delay);
        }
    }, [open, handleClose, delay]);

    return (
        <>
            <Snackbar
                autoHideDuration={4000}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right'
                }}
                open={open}
                onClose={handleClose}
            >
                <Alert onClose={handleClose} severity={status} sx={{ width: '100%' }}>{message}</Alert>
            </Snackbar>
        </>
    )
}