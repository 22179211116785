import React from 'react'
import { Layouts } from './index'
import Suspense from '../Suspense/Suspense'

const Layout = (props:any) => {
    const Layoutx = Layouts.layout1
    return (
        <Suspense>
            <Layoutx {...props} />
        </Suspense>
    )
}

export default Layout
