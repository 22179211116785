import React from 'react';
import { useSelector } from 'react-redux'
import { FCState } from './stores/RootReducer';
import Routing, { Auth } from "./Routing";
import { CssBaseline, ThemeProvider } from '@mui/material'
import Layout from './components/Layouts/Layout';
import { BrowserRouter } from "react-router-dom";
import { createTheme } from '@mui/material'
import Toast from './components/CustomSnackBar/Toast';

function App() {
  let user = useSelector((state: FCState) => state.login)
  let { admin } = useSelector((state: FCState) => state.settings)
  let activeTheme = createTheme({ ...admin.themes[admin.activeTheme] })

  return (<BrowserRouter>
    <ThemeProvider theme={activeTheme}>
      <CssBaseline />
      <Toast />
      {user.userData ? <Layout><Routing user={user} /></Layout> : <Auth />}
    </ThemeProvider>
  </BrowserRouter>);
}

export default App;
