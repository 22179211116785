import React,{PropsWithChildren, ReactNode, Suspense} from 'react'
import  Loading  from '../Loading/Loading'

  interface ISuspense  {
    children: React.ReactNode
  }
const SuspenseCard = ({ children }:ISuspense ) => {
    return <Suspense fallback={<Loading />}>{children}</Suspense>
}

export default SuspenseCard
