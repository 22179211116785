import { lazy } from 'react';
const Login = lazy(() => import("./view/login"));
const Signup = lazy(() => import("./view/signup"));
const ForgotPassword = lazy(() => import("./view/forgotPassword")); 
const Email = lazy(() => import("./view/Email")); 
const Otp = lazy(() => import("./view/Otp")); 

export default  {
    routesProps:
    [{
        path: '/',
        exact: true,
        component: Login
    },{
        path: '/signup',
        exact: true,
        component: Signup
    },{
        path: '/email',
        exact: true,
        component: Email,
    },{
        path: '/otp',
        exact: true,
        component: Otp,
    },{
        path: '/forgot-password',
        exact: true,
        component: ForgotPassword,
    }
]};