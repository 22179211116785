import { Navigate, Route, Routes } from "react-router-dom";
import modules, { AuthRoute } from './modules';
import SuspenseCard from './components/Suspense/Suspense';
import PermissionCheck from "./components/PermissionCheck/PermissionCheck";

const Routing = ({ user }: any) => {
  return (
    <Routes>
      {modules.map((module: any) => <Route
        path={module.path}
        element={<SuspenseCard>
          <PermissionCheck permission={module?.permission}>
            <module.component />
          </PermissionCheck>
        </SuspenseCard>}
        key={Math.random()}
      />)}

      {modules.map((module: { children: any[]; }): any => {
        if (typeof module.children != 'undefined') {
          return module.children.map((mod): any => <Route
            path={mod.path}
            element={<SuspenseCard>
              <PermissionCheck permission={mod?.permission}>
                <mod.component />
              </PermissionCheck>
            </SuspenseCard>}
            key={mod.name + Math.random()} />)
        }
      })}
      <Route path="*" element={<Navigate replace to="/" />} />
    </Routes>
  );
};

export const Auth = () => {
  return (
    <Routes>
      {AuthRoute.map(module => <Route key={Math.random()} path={module.path}
        element={<SuspenseCard><module.component /></SuspenseCard>} />)}
      <Route path="*" element={<Navigate replace to="/" />} />
    </Routes>
  )
}

export default Routing;
