import {useSelector} from 'react-redux';
import { FCState } from '../stores/RootReducer'

export const convertHexToRGB = (hex:any) => {
  // check if it's a rgba
  if (hex.match('rgba')) {
      let triplet = hex.slice(5).split(',').slice(0, -1).join(',')
      return triplet
  }

  let c:any
  if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
      c = hex.substring(1).split('')
      if (c.length === 3) {
          c = [c[0], c[0], c[1], c[1], c[2], c[2]]
      }
      c = '0x' + c.join('')

      return [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(',')
  }
}  
export const client = (endpoint:string, { body, ...customConfig }:any = {}) => {
  let localData:any =localStorage.getItem('persist:root');
  localData =  JSON.parse(localData)

  let user:any = JSON.parse(localData.login).userData
  
  const headers:any = { "Content-Type": "application/json"};

  if (user && user.token) {
    headers.Authorization = `Bearer ${user.token}`;
  } 
  const config:any = {
    method: body ? "POST" : "GET",
    ...customConfig,
    headers: {
      ...headers,
      ...customConfig.headers,
    },
  };

  if (body) {
    config.body = JSON.stringify(body);
  }
 
 return fetch(`${process.env.REACT_APP_API_URL}${endpoint}`, config).then( 
        async (res) => {
          
          const data = await res.json();
    
          if (res.ok) {
            return data;
          } else {
            return Promise.reject(data);
          }
        }
      );
  
};
export const getPlaceDetails = (placeId:string) => {
 
  const config:any = {
    method:  "GET"
  };

 
 return fetch(`https://maps.googleapis.com/maps/api/place/details/json?place_id=${placeId}&key=${process.env.REACT_APP_GOOGLE_MAP_KEY}`, config).then( 
        async (res) => {
          
          const data = await res.json();
          let returnData:any = {
            country : '',
            city: '',
            state: '',
            postalCode: '',
            locationName:''
          }
          if(data?.status == "OK"){
            let addr = data?.result?.address_components
            for (let i in addr) {
              if(addr[i]?.types.includes('administrative_area_level_2')){
                returnData.city = addr[i]?.long_name || ''
              }else if(addr[i]?.types.includes('administrative_area_level_1')){
                returnData.state = addr[i]?.long_name || ''
              }else if(addr[i]?.types.includes('country')){
                returnData.country = addr[i]?.long_name || ''
              }else if(addr[i]?.types.includes('postal_code')){
                returnData.postalCode = addr[i]?.long_name || ''
              }
              else if(addr[i]?.types.includes('neighborhood')){
                returnData.locationName = addr[i]?.long_name || ''
              }
            }
            return returnData;

          }else{
            return false;
          }
         
        }
      );
  
};
  
// export const uploadImage = (endpoint, { body,file, ...customConfig } = {},user) => {
  
//   const headers = {};
//   if (user && user.token) {
//     headers.Authorization = `Bearer ${user.token}`;
//   } 
   

//   const config = {
//     method: "POST",
//     ...customConfig,
//     headers: {
//       ...headers,
//       ...customConfig.headers,
//     },
//   };


//   const data = new FormData();
//   data.append("file", file);
//   config.body = data;

//   return fetch(`${process.env.API_URL}${endpoint}`, config).then((res) => res.json());
// };