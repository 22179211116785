import { forEach, merge } from 'lodash'
import themeOptions from './themeOptions'
import { themeColors } from './themeColors'

function createThemes() {
  let themes: any = {}
  forEach(themeColors, (value: any, key: string | number) => {
    themes[key] = merge({}, themeOptions, value)
  })
  return themes
}
export const themes = createThemes()
