import { AlertAction, IAlertState } from './AlertInterface';
import { AlertType } from './AlertTypes';

const initialState: IAlertState = {
    status: '',
    message: '',
    open: false,
    delay: 4000
}

export const alertReducer = (state: IAlertState = initialState, action: AlertAction) => {
    switch (action.type) {
        case AlertType.ALERT_SUCCESS:
            return {
                ...state,
                open: true,
                status: 'success',
                message: action.payload.message,
                delay: action.payload?.delay ? action.payload.delay : state.delay
            }
        case AlertType.CLEAR_ALERT:
            return {
                ...state,
                open: false
            }
        case AlertType.ALERT_ERROR:
            return {
                ...state,
                open: true,
                status: 'error',
                message: action.payload.message,
                delay: action.payload?.delay ? action.payload.delay : state.delay
            }
        default:
            return state;
    }
}